
tr.line-item.total {
  border: 1px solid black;
}

.item-table{
  border-spacing: 15px;
  width: 50%
}
.item-table2{
  border-spacing: 15px;
}
#checkout-total{
  color: green
}
.tabContent{
  padding-top: 5%
}

.child{
  margin: auto auto;
  min-width: 45%;
  width: 50%;
}
.tiny-child{
  margin: auto auto;
  min-width: 35%;
  width: 40%;
}
.paper{
    padding-top: 5%;
    margin-top: 30%;
    min-height: 70%;
    min-width: 40%;
    z-index: 1000;
}
.successPaper:{
  height: 50%
}

.quantity-table{
  min-width: 45%;
  width: 50%;
}

@media (max-width: 640px) {
  .child{
    height: 100% !important;
    width: 100% !important;
  }
  .tiny-child{
    height: 100% !important;
    width: 100% !important;
  }
  .paper{
    height: 100% !important;
    width: 100% !important;
    margin-top: 0px;
  }
  .quantity-table{
    width: 100% !important;
  }
 }


 .form{
   padding-left: 10%;
   padding-right: 10%;
   padding-bottom: 5%;
   padding-top: 2%;
   text-align: center;
 }
 @media (max-width: 640px) {
   .form{
     padding-left: 5% !important;
     padding-right: 5% !important;
   }
  }

.stripes {
  position: absolute;
  width: 100%;
  z-index: -1;
  transform: skewY(-12deg);
  height: 80%;
  top: -350px;
  background: linear-gradient(180deg, #e6ebf1 350px, rgba(230, 235, 241, 0));
}

.stripes .stripe {
  position: absolute;
  height: 190px;
}

.stripes .s1 {
  height: 380px;
  top: 0;
  left: 0;
  width: 24%;
  background: linear-gradient(90deg, #e6ebf1, rgba(230, 235, 241, 0));
}

.stripes .s2 {
  top: 380px;
  left: 4%;
  width: 35%;
  background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0.65),
    hsla(0, 0%, 100%, 0)
  );
}

.stripes .s3 {
  top: 380px;
  right: 0;
  width: 38%;
  background: linear-gradient(90deg, #e4e9f0, rgba(228, 233, 240, 0));
}
